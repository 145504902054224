






















































































import PriceTag from './PriceTag.vue'
import SweetalertIcon from 'vue-sweetalert-icons'
import billing from '../apis/billing'
import dropin from 'braintree-web-drop-in'
import { mapGetters, mapActions } from 'vuex'

const taxesByLocation = {
  ab: [{ name: 'GST', rate: 0.05 }],
  bc: [{ name: 'GST', rate: 0.05 }],
  mb: [{ name: 'GST', rate: 0.05 }],
  nb: [{ name: 'HST', rate: 0.15 }],
  nl: [{ name: 'HST', rate: 0.15 }],
  ns: [{ name: 'HST', rate: 0.15 }],
  nt: [{ name: 'GST', rate: 0.05 }],
  nu: [{ name: 'GST', rate: 0.05 }],
  on: [{ name: 'HST', rate: 0.13 }],
  pe: [{ name: 'HST', rate: 0.15 }],
  qc: [{ name: 'GST', rate: 0.05 }, { name: 'QST', rate: 0.0975 }],
  sk: [{ name: 'GST', rate: 0.05 }],
  yt: [{ name: 'GST', rate: 0.05 }]
}

export default {
  components: {
    PriceTag,
    SweetalertIcon
  },
  data: () => ({
    showLocationSelctionHint: false,
    paymentDialog: false,
    paymentMethodRequestable: false,
    clientToken: null,
    clientTokenError: null,
    braintreeDropinInstance: null,
    locations: [],
    location: null,

    isPaying: false,
    isProcessing: false,
    processingError: null
  }),
  computed: {
    desiredNewPlans () {
      return this.desiredSubscriptionChanges
        .map(({ plan }) => this.plan(plan))
    },
    unchangedPlans () {
      const desiredChanges = this.desiredSubscriptionChanges
      return this.subscriptions
        .filter(x => !desiredChanges.some(y => y.device === x.device && y.option === x.option))
        .map(({ plan }) => this.plan(plan))
    },
    mensuality () {
      return this.desiredNewPlans
        .concat(this.unchangedPlans)
        .filter(plan => plan && plan.period && plan.price)
        .reduce((sum, plan) => sum + plan.price, 0)
    },
    immediate () {
      return this.desiredNewPlans
        .filter(plan => plan && !plan.period && plan.price)
        .reduce((sum, plan) => sum + plan.price, 0)
    },
    taxes () {
      return taxesByLocation[this.location] || []
    },
    ...mapGetters(['subscriptions', 'desiredSubscriptionChanges', 'plan'])
  },
  watch: {
    async paymentDialog (newState, _oldState) {
      if (newState && (this.immediate || this.mensuality)) {
        try {
          this.clientToken = (await billing.clientToken()).data
        } catch (e) {
          this.clientTokenError = this.$t('server-error')
          throw e
        }
        const instance = await dropin.create({
          authorization: this.clientToken,
          container: '#dropin-container',
          locale: this.$i18n.locale.replace('-', '_')
        })

        this.braintreeDropinInstance = instance
        this.paymentMethodRequestable = instance.isPaymentMethodRequestable()
        instance.on('paymentMethodRequestable', _event => { this.paymentMethodRequestable = true })
        instance.on('noPaymentMethodRequestable', () => { this.paymentMethodRequestable = false })
      } else {
        await this.teardownBraintree()
        this.processingError = this.clientTokenError = null
        this.isPaying = this.paymentMethodRequestable = false
      }
    }
  },
  methods: {
    async processPayment () {
      let paymentMethodNonce: string
      if (this.immediate || this.mensuality) {
        paymentMethodNonce = (await this.braintreeDropinInstance.requestPaymentMethod()).nonce
        await this.teardownBraintree()
      } else {
        paymentMethodNonce = ''
      }
      try {
        this.isProcessing = this.isPaying = true
        const result = await billing.changePlans(this.desiredSubscriptionChanges, paymentMethodNonce)
        await this.refreshSubscriptions(result.data)
      } catch (e) {
        if (e.response?.status === 400 && e.response.data.code === 'payment-declined') {
          this.processingError = this.$t('payment-declined')
        } else {
          this.processingError = this.$t('unknown-error-billing')
        }
      } finally {
        this.isProcessing = false
      }
    },
    addTaxes (amount) {
      return this.taxes.map(x => x.rate * amount).reduce((a, b) => a + b, amount)
    },
    async teardownBraintree () {
      this.clientToken = null
      if (this.braintreeDropinInstance) {
        await this.braintreeDropinInstance.teardown()
        this.braintreeDropinInstance = null
        document.getElementById('dropin-container').innerHTML = ''
      }
    },
    ...mapActions(['refreshSubscriptions'])
  },
  mounted () {
    this.locations = Object.keys(taxesByLocation).map(x => ({ location: x, name: this.$t(x) })).sort((a, b) => a.name.localeCompare(b.name))
  }
}
