import { render, staticRenderFns } from "./DeviceOptions.vue?vue&type=template&id=480c7458&scoped=true&"
import script from "./DeviceOptions.vue?vue&type=script&lang=js&"
export * from "./DeviceOptions.vue?vue&type=script&lang=js&"
import style0 from "./DeviceOptions.vue?vue&type=style&index=0&id=480c7458&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "480c7458",
  null
  
)

/* custom blocks */
import block0 from "./DeviceOptions.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
installComponents(component, {VAlert,VCard,VCardTitle,VIcon,VImg,VList,VListItem,VListItemContent,VListItemIcon})
