<template>
  <span>
    <span class="dollar">
      {{dollarPart(price)}}<sup><span class="cents">{{centsPart(price)}}</span></sup>
    </span>
    <span v-if="period" class="period">/{{$t(period)}}</span>
  </span>
</template>

<script>
export default {
  props: {
    price: Number,
    period: String
  },
  methods: {
    dollarPart: (price) => Math.trunc(price),
    centsPart: (price) => ('0' + Math.round((price - Math.trunc(price)) * 100)).slice(-2)
  }
}
</script>

<style lang="scss" scoped>
.cents { font-size: 75%; }
.period { font-size: 75%; }
</style>

<i18n>
{
  "en": {
    "month": "month"
  },
  "fr": {
    "month": "mois"
  }

}
</i18n>
