import { render, staticRenderFns } from "./DeviceOption.vue?vue&type=template&id=2b806f94&scoped=true&"
import script from "./DeviceOption.vue?vue&type=script&lang=ts&"
export * from "./DeviceOption.vue?vue&type=script&lang=ts&"
import style0 from "./DeviceOption.vue?vue&type=style&index=0&id=2b806f94&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b806f94",
  null
  
)

/* custom blocks */
import block0 from "./DeviceOption.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBottomSheet,VBtn,VIcon,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle})
