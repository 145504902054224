

































import { mapActions, mapGetters } from 'vuex'
import devices from '../apis/devices'

interface Vst {
  getDevices(options: any, cb: (devices: Array<string>) => void): void;
}

declare global {
  interface Window {
    vst: Vst;
  }
}

export default {
  data: () => ({
    deviceChoicesShown: false,
    available: false,
    detectedDevices: [],
    deducedDevices: [],
    deviceOwnershipsBySerialNumber: {}
  }),
  methods: {
    openVigiltekProductPage () {
      window.open('https://vigiltek.com/products', '_blank')
    },
    ...mapActions(['addDeviceToAccount', 'refreshOwnedDevices'])
  },
  watch: {
    available (newAvailable: boolean, _oldAvailable: boolean) {
      if (newAvailable) {
        window.vst.getDevices({}, devices => {
          this.detectedDevices = devices
        })
      }
    },
    surroundingDevices: {
      immediate: true,
      async handler (newDevices, _oldDevices) {
        newDevices = newDevices.filter(x => !this.deviceOwnershipsBySerialNumber[x])
        if (newDevices.length > 0) {
          (await devices.ownershipStatus(newDevices)).data
            .forEach(x => {
              this.$set(this.deviceOwnershipsBySerialNumber, x.serialNumber, x.ownership)
            })
        }
      }
    },
    ownedDevices (newDevices: Array<any>, _oldDevices: Array<any>) {
      newDevices.forEach(x => { this.$set(this.deviceOwnershipsBySerialNumber, x.serialNumber, x.ownership) })
    }
  },
  computed: {
    surroundingDevices () {
      return this.detectedDevices.concat(this.queryParamsDevices, this.deducedDevices)
    },
    unownedSurroundingDevices () {
      return this.surroundingDevices
        .filter(x => this.deviceOwnershipsBySerialNumber[x] === 'unowned')
    },
    queryParamsDevices () {
      return Array.isArray(this.$route.query.sn)
        ? this.$route.query.sn
        : this.$route.query.sn
          ? [this.$route.query.sn]
          : []
    },
    ...mapGetters(['ownedDevices'])
  },
  mounted () {
    const isReady = () => { return typeof window.vst !== 'undefined' && typeof window.vst.getDevices !== 'undefined' }
    this.available = isReady()
    document.addEventListener('vstready', function (_) { this.available = isReady() }.bind(this))
    this.refreshOwnedDevices().then(ownedDevices => {
      this.deviceChoicesShown = !!this.queryParamsDevices.find(x => !ownedDevices.some(y => y.serialNumber === x))
    })
  }
}