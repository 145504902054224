

































import DeviceOptionSubscriptionModifications from './DeviceOptionSubscriptionModifications.vue'
import PriceTag from '../PriceTag.vue'

export default {
  name: 'DeviceOption',
  props: {
    device: Object,
    option: Object
  },
  components: {
    DeviceOptionSubscriptionModifications,
    PriceTag
  },
  data: () => ({
    sheets: {}
  }),
  computed: {
    currentSubscription () { return this.$store.getters.currentSubscription(this.device.serialNumber, this.option.id) },
    desiredSubscriptionChange () { return this.$store.getters.desiredSubscriptionChange(this.device.serialNumber, this.option.id) },
    subscription () { return this.desiredSubscriptionChange || this.currentSubscription },
    plan () { return this.$store.getters.plan(this.subscription?.plan) },
    currentExpiration () { return this.currentSubscription?.expiration },
    isIncluded () { return this.plan?.included },
    isBought () { return !this.desiredSubscriptionChange && this.subscription && !this.isIncluded && !this.period },
    willBeBilled () { return this.subscription?.plan && !this.isBought && !this.isIncluded },
    period () { return this.plan?.period },
    price () { return this.plan?.price }
  }
}
