












import { Component, Vue } from 'vue-property-decorator'
import BillingBar from '../components/BillingBar.vue'
import AddDevices from '../components/AddDevice.vue'
import OwnedDevices from '../components/options/OwnedDevices.vue'
import Authenticated from '../auth/Authenticated.vue'

@Component({
  components: {
    Authenticated,
    BillingBar,
    AddDevices,
    OwnedDevices
  }
})

export default class Devices extends Vue {}

