










import { mapActions } from 'vuex'
import DeviceOptions from './DeviceOptions.vue'

export default {
  components: {
    DeviceOptions
  },
  computed: {
    devices () { return this.$store.getters.ownedDevices }
  },
  methods: {
    ...mapActions(['refreshPlans', 'refreshOptions', 'refreshSubscriptions', 'refreshOwnedDevices'])
  },
  mounted () {
    this.refreshPlans()
    this.refreshOptions()
    this.refreshSubscriptions()
  }
}
