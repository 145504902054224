






























import PriceTag from '../PriceTag.vue'

export default {
  name: 'DeviceOptionSubscriptionModifications',
  components: {
    PriceTag
  },
  props: {
    device: Object,
    option: Object
  },
  computed: {
    eligiblePlans () {
      return this.option.plans.filter(x => x !== this.desiredSubscription?.plan).map(this.$store.getters.plan)
    },
    desiredSubscriptionChange () {
      return this.$store.getters.desiredSubscriptionChange(this.device.serialNumber, this.option.id)
    },
    currentSubscription () {
      return this.$store.getters.currentSubscription(this.device.serialNumber, this.option.id)
    },
    desiredSubscription () {
      return this.desiredSubscriptionChange || this.currentSubscription
    },
    desiredPlan () {
      return this.$store.getters.plan(this.desiredSubscription?.plan)
    }
  },
  methods: {
    addSubscription (planId) {
      this.$store.dispatch('addDesiredSubscription', { deviceSerialNumber: this.device.serialNumber, optionId: this.option.id, planId })
      this.$emit('selection')
    },
    removeSubscription () {
      this.$store.dispatch('addDesiredSubscription', { deviceSerialNumber: this.device.serialNumber, optionId: this.option.id, planId: null })
      this.$emit('selection')
    }
  }
}
