<template>
  <v-card max-width="400" class="mx-auto">
    <v-img>
      <v-card-title>{{device.serialNumber}}</v-card-title>
    </v-img>
    <v-alert v-if="existsIncludedOption && !currentIncludedOptionId && !desiredDeviceSubscriptionChanges.length" type="info" border="bottom" dense text>
      {{$t('device-has-included')}} {{$t('choose-free-option')}}
      <v-list dense light>
          <v-list-item v-for="optionPlan in availableFreeOptionPlans"
                  :key="optionPlan.option.id"
                  @click="addDesiredSubscription({deviceSerialNumber: device.serialNumber, optionId: optionPlan.option.id, planId: optionPlan.plan.id})">
            <v-list-item-icon>
              <v-icon>mdi-arrow-right-bold</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{optionPlan.option.name}}
            </v-list-item-content>
          </v-list-item>
      </v-list>
    </v-alert>
    <v-list>
      <DeviceOption v-for="option in options" :key="option.id" :device="device" :option="option"/>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DeviceOption from './DeviceOption.vue'

export default {
  name: 'DeviceOptions',
  components: {
    DeviceOption
  },
  props: {
    device: Object
  },
  data: () => ({
    newSubscriptions: []
  }),
  computed: {
    options () { return this.$store.getters.options(this.device.type) },
    existsIncludedOption () { return this.availableFreeOptionPlans.length > 0 },
    currentIncludedOptionId () { return this.$store.getters.currentIncludedOptionId(this.device.serialNumber) },
    desiredDeviceSubscriptionChanges () {
      return this.desiredSubscriptionChanges.filter(x => x.device === this.device.serialNumber)
    },
    availableFreeOptionPlans () {
      return this.options
        .map(o => o.plans.map(p => ({ option: o, plan: this.plan(p) })))
        .reduce((a, b) => a.concat(b))
        .filter(op => op.plan.included)
    },
    ...mapGetters(['plan', 'desiredSubscriptionChanges'])
  },
  methods: {
    ...mapActions(['addDesiredSubscription'])
  }
}
</script>

<style lang="scss" scoped>
.v-card__title { background:LightSteelBlue; }
</style>

<i18n>
{
  "en": {
    "device-has-included": "This devices comes with an included option.",
    "choose-free-option": "Select the free option to activate:"
  },
  "fr": {
    "device-has-included": "Cet appareil inclut un choix d'option.",
    "choose-free-option": "Choisissez l'option à activer gratuitement:"
  }
}
</i18n>
